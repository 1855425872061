import React from "react";
import {observer} from "mobx-react-lite";
import {authStore} from "../../store/authStore";

const InviteShowPopup = observer(() => (
    <div className="invite-popup">{authStore.inviteMessage}
        {authStore.inviteMessageUrlMetaMask && <a href={authStore.inviteMessageUrlMetaMask} target={"_blank"}>Download MetaMask</a>}
    </div>
));

export default InviteShowPopup;