const RejectSvg = () => {
    return (
        <svg width="3.2rem" height="3.3rem" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.981689" width="32" height="32" rx="8" fill="#FCE6E6"/>
            <g clipPath="url(#clip0_2764_37442)">
                <circle cx="16" cy="16.9816" r="8.33333" stroke="#E00201" strokeWidth="1.5"/>
                <path d="M18.0833 14.8983L13.9166 19.065M13.9166 14.8983L18.0833 19.065" stroke="#E00201"
                      strokeWidth="1.5" strokeLinecap="round"/>
            </g>
            <defs>
                <clipPath id="clip0_2764_37442">
                    <rect width="20" height="20" fill="white" transform="translate(6 6.98169)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default RejectSvg;