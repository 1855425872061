import { observer } from "mobx-react-lite"
import { errorStore } from "../../../store/errorStore"
import { translationStore } from "../../../store/translationStore";
import styles from './style.module.scss'

 const WarningPopup = observer(()=> {
    const { warningMap } = errorStore;
    const { translationData } = translationStore;

    return (
        <>
        {
            warningMap.map((item, i) => {
                return (
                    <div key={i} className={`${styles.warning_popup} ${styles[item.type]}`}>
                        <div className={styles.popup_icon}></div>
                        <div className={styles.modal_text}>{translationData?.[item.key] || item.key}</div>
                    </div>
                )
            })
        }
        </>
    )
})

export default WarningPopup