import {makeAutoObservable} from "mobx";
import {cacheService} from "../services/cacheService";

class UcraftStore {
    isConnected = !!cacheService.get("ac_token");
    nonceToken = "";
    ucraftUserAccountToggle = false;
    toggleECommerce = false;
    removeShopPopup = false;
    ucraftData = {};
    eCommerceData = [];
    eCommerceUrl = "";
    activeECommerce = -1;
    ecommerceDomain = "";
    connectecommerceDomain = !!cacheService.get("connectEcommerceShop");
    ecommerceIframeToggle = false;
    ecommerceProductId = cacheService.get("ecommerceProductId") || 0;
    removeEcommerceToggle = false;
    shopUrl = "";
    connectedPopup = false;
    publishedPopup = false;

    constructor() {
        makeAutoObservable(this);
    }

    getEcommerceDomain = ()=> this.ecommerceDomain;
    setIsConnected = bool => this.isConnected = bool;
    setIsConnectedPopup = bool => this.connectedPopup = bool;
    setIsPublishedPopup = bool => this.publishedPopup = bool;
    setNonceToken = str => this.nonceToken = str;
    setUcraftUserAccountToggle = bool => this.ucraftUserAccountToggle = bool;
    setToggleECommerce = bool => this.toggleECommerce = bool;

    setRemoveShopPopup = bool => this.removeShopPopup = bool;

    setUcraftData = data => this.ucraftData = data;

    setECommerceData = data => this.eCommerceData = data;
    setECommerceUrl = string => this.eCommerceUrl = string;
    setActiveECommerce = number => this.activeECommerce = number;
    setEcommerceDomain = string => this.ecommerceDomain = string;
    setConnectecommerceDomain = bool => this.connectecommerceDomain = bool;
    setEcommerceIframeToggle = bool => this.ecommerceIframeToggle = bool;
    setEcommerceProductId = number => {
        cacheService.set("ecommerceProductId", number);
        this.ecommerceProductId = number
    };

    setRemoveEcommerceToggle = bool => this.removeEcommerceToggle = bool;
    setShopUrl = string => this.shopUrl = string;

    setCleanUp = () => {
        this.connectedPopup = true
        this.isConnected = "";
        this.ucraftAuthToggle = false;
        this.nonceToken = "";
        this.ucraftUserAccountToggle = false;
        this.toggleECommerce = false;
        this.removeShopPopup = false;
        this.eCommerceUrl = "";
        this.activeECommerce = -1;
        this.ecommerceDomain = "";
        this.connectecommerceDomain = false;
        this.ecommerceIframeToggle = false;
        this.ecommerceProductId = 0;
        this.removeEcommerceToggle = false;
        this.shopUrl = "";

        cacheService.set("ac_token", "");
        cacheService.set("ecommerceProductId", "");
        cacheService.set("connectEcommerceShop", "");
    };
}

export const ucraftStore = new UcraftStore();
