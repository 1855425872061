import style from "./svgJs.module.scss";

const PandaMrLogo = ({onlyWhite = false}) => {
    return (
        <svg width="11.8rem" height="8.8rem"
             viewBox="0 0 118 88" fill="none">
            <g clipPath="url(#clip0_344_5809)">
                <path
                    d="M60.0885 8.3478C71.2612 8.3478 76.8443 12.1254 76.8377 19.6805V43.7259H69.8795L65.9034 39.8113C64.4087 41.4623 62.5145 42.7146 60.3966 43.4519C58.2938 44.2726 56.058 44.7135 53.7963 44.7535C52.1259 44.7936 50.4569 44.6325 48.8262 44.2739C47.5104 43.9245 46.2614 43.3659 45.1285 42.62C42.4778 40.9759 41.1524 37.9095 41.1524 33.4207C41.1524 25.1805 47.4776 21.0572 60.1282 21.0506H62.9214V18.5844C61.4304 18.4866 60.2077 18.4474 59.2237 18.4474C54.6429 18.3516 50.076 18.976 45.6951 20.2971L44.0152 10.5106C49.2334 9.00441 54.6511 8.27541 60.0885 8.3478ZM53.2198 31.9038V34.7223H56.0825C58.1302 34.7223 60.9333 34.644 62.0466 34.0373C62.3382 33.8425 62.5719 33.5749 62.7238 33.2619C62.8758 32.9489 62.9406 32.6018 62.9115 32.2561V29.0266H60.0487C58.1531 28.9826 56.2586 29.1533 54.4026 29.5355C53.6074 29.878 53.2198 30.6707 53.2198 31.9038Z"
                    className={onlyWhite ? style.onlyWhiteFill : style.onlyBlackFill}/>
                <path
                    d="M60.0885 51.3006C71.2612 51.3006 76.8443 55.0815 76.8377 62.6431V86.6787H69.8795L65.9034 82.7641C64.4087 84.4151 62.5145 85.6674 60.3966 86.4047C58.2957 87.2329 56.059 87.6773 53.7963 87.7161C52.1255 87.7537 50.4563 87.5893 48.8262 87.2268C47.5093 86.8851 46.2595 86.3294 45.1285 85.5826C42.4778 83.932 41.1524 80.8623 41.1524 76.3736C41.1524 68.1334 47.4776 64.0133 60.1282 64.0133H62.9214V61.5862C61.4304 61.4981 60.2077 61.4492 59.2237 61.4492C54.642 61.351 50.0742 61.9788 45.6951 63.3086L44.0152 53.5222C49.2303 51.996 54.6481 51.2472 60.0885 51.3006ZM53.2198 74.8665V77.685H56.0825C58.1302 77.685 60.9333 77.6067 62.0466 76.9999C62.3397 76.8043 62.5745 76.5351 62.7265 76.2203C62.8786 75.9054 62.9424 75.5563 62.9115 75.209V71.9794H60.0487C58.1527 71.9379 56.2581 72.112 54.4026 72.4981C53.6074 72.8406 53.2198 73.6334 53.2198 74.8665Z"
                    className={onlyWhite ? style.onlyWhiteFill : style.onlyBlackFill}/>
                <path
                    d="M90.0979 9.37547L93.5869 12.8105C97.6362 9.9242 102.504 8.36317 107.503 8.3479C110.764 8.3479 113.321 9.046 115.177 10.4422C116.089 11.1112 116.82 11.99 117.307 13.0017C117.794 14.0134 118.022 15.1272 117.97 16.2456V43.726H104.054V19.6806H101.897C100.675 19.6255 99.4533 19.8016 98.2986 20.1993C97.8962 20.3757 97.5616 20.674 97.3434 21.0507C97.1253 21.4274 97.035 21.8629 97.0858 22.2936V43.726H83.1696V9.37547H90.0979Z"
                    className={onlyWhite ? style.onlyWhiteFill : style.onlyBlackFill}/>
                <path
                    d="M35.9338 86.7864H28.9757L25.9936 83.8994C23.9459 86.5091 20.3012 87.814 15.0594 87.814C12.5241 87.8784 10.0106 87.3379 7.73349 86.2384C5.78161 85.2706 4.15821 83.7641 3.06159 81.903C0.984747 78.136 -0.0440071 73.8956 0.0795294 69.6112C0.0795294 63.798 1.38169 59.4006 3.98602 56.419C5.09994 55.178 6.48711 54.2039 8.04163 53.5711C9.92703 52.7858 11.9589 52.3991 14.0057 52.4359H22.0275V40.1735L35.9438 37.1886L35.9338 86.7864ZM14.9998 64.2775V77.7437H17.1667C18.3599 77.797 19.5535 77.648 20.6955 77.3033C21.0883 77.1278 21.4197 76.8418 21.6479 76.4814C21.876 76.1209 21.9908 75.702 21.9777 75.2775V61.4395H19.8207C18.576 61.3703 17.3296 61.5432 16.1528 61.9483C15.3874 62.2909 14.9998 63.0836 14.9998 64.3167V64.2775Z"
                    className={onlyWhite ? style.onlyWhiteFill : style.onlyBlackFill}/>
                <path
                    d="M0.994003 1.02753H7.95214L10.9342 3.91455C12.9819 1.30483 16.6266 -3.24431e-05 21.8684 -3.24431e-05C24.4039 -0.065681 26.9177 0.47496 29.1943 1.57557C31.1482 2.54055 32.7723 4.0477 33.8662 5.91099C35.9475 9.67626 36.9766 13.9179 36.8483 18.2028C36.8483 24.0159 35.5461 28.4133 32.9418 31.3949C31.8319 32.636 30.4479 33.6102 28.8961 34.2428C27.0106 35.0278 24.9788 35.4146 22.932 35.378H14.9103V47.6405L0.994003 49.6467V1.02753ZM21.928 23.5364V10.0311H19.7611C18.5711 9.9768 17.3806 10.1258 16.2423 10.4715C15.8492 10.6476 15.5172 10.9334 15.2875 11.2935C15.0578 11.6536 14.9405 12.0722 14.9501 12.4973V26.3549H17.117C18.3585 26.4235 19.6015 26.2506 20.775 25.846C21.5404 25.5231 21.928 24.7304 21.928 23.4973V23.5364Z"
                    className={onlyWhite ? style.onlyWhiteFill : style.onlyBlackFill}/>
                <path
                    d="M113.318 53.0426H87.9707C85.632 53.0426 83.7361 54.9091 83.7361 57.2116V67.918C83.7361 70.2205 85.632 72.0871 87.9707 72.0871H113.318C115.657 72.0871 117.553 70.2205 117.553 67.918V57.2116C117.553 54.9091 115.657 53.0426 113.318 53.0426Z"
                    className={onlyWhite ? style.onlyWhiteFill : style.onlyBlackFill}/>
                <path
                    d="M93.4974 68.1235V61.5862C93.4898 61.4908 93.5017 61.3949 93.5324 61.3041C93.5632 61.2133 93.6121 61.1295 93.6763 61.0577C93.7751 60.9492 93.9082 60.8768 94.0541 60.8522C94.259 60.8331 94.4654 60.8331 94.6704 60.8522H94.9189V68.1823H98.4974V61.5275C98.4956 61.4428 98.5124 61.3587 98.5467 61.281C98.581 61.2032 98.632 61.1338 98.6962 61.0773C98.8029 60.9776 98.9382 60.9126 99.0838 60.8914C99.2822 60.8732 99.4819 60.8732 99.6803 60.8914H99.9288V68.2214H103.507V60.0497C103.514 59.7626 103.443 59.4788 103.302 59.2276C103.16 58.9765 102.953 58.767 102.702 58.6209C102.018 58.2445 101.238 58.068 100.456 58.112C99.5639 58.0914 98.6858 58.3296 97.9308 58.797C97.5855 58.4925 97.1811 58.26 96.742 58.1137C96.3029 57.9674 95.8383 57.9102 95.3761 57.9456C93.6664 57.8673 92.6823 58.3762 92.3245 59.0613L91.7082 58.0826H89.919V68.2018L93.4974 68.1235Z"
                    className={onlyWhite ? style.onlyBlackFill : style.onlyWhiteFill}/>
                <path
                    d="M105.406 58.3566H107.394L108.487 59.4527C110.316 56.5853 112.463 57.3584 112.632 57.4954L112.573 61.9483C111.988 61.8572 111.391 61.8771 110.813 62.007C110.301 62.0843 109.813 62.2714 109.382 62.555V68.1529H105.406V58.3566Z"
                    className={onlyWhite ? style.onlyBlackFill : style.onlyWhiteFill}/>
            </g>
            <defs>
                <clipPath id="clip0_344_5809">
                    <rect width="118" height="87.814" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default PandaMrLogo;