import {cacheService} from "../services/cacheService";

export const alternativeGuest = () => {
    const lang = cacheService.get("Language");
    if(cacheService.get("AlternateGuest")) {
        const roomId = cacheService.get("AlternateGuest")
        cacheService.clear();
        cacheService.set("AlternateGuest", roomId);
        cacheService.set("Language", lang);
    }
}