import {makeObservable, observable, action} from "mobx";
import uploadPlaceholder from "../assets/images/modal/thumbnail-camera.png";
import {validateFormKeys} from "../helpers/validateFormKeys";
import {errorStore} from "./errorStore";
import apiService from "../services/apiServices";
import {keyboardLockStore} from "./keyboardLockStore";


class NftStore {
    nftIsActive = false;
    nftId = 0;
    external_id = 0;
    nftUrl = "";
    nftPopup = false;
    nftDescription = "";
    nftName = "";
    nftPrice = "";
    links = "";
    imageSrc = uploadPlaceholder;
    imageFile = null;
    isLoadedModel = false;
    dropsValue = "";
    descriptionValue = "";
    priceValue = "";
    priceType = "USD";
    toggle = false;
    owner = "";
    creator = "";
    priceList = [];
    isSaleable = false;
    blockchain = "";
    tokenStandard = "";
    contractAddress = "";
    nftHistory = [];
    showNftHistory = false;
    ftnNftHistory = [];
    selectedCerebrumNft = 0;
    metamaskAddress = "";
    walletProvider = false;
    balance = "";

    constructor() {
        makeObservable(this, {
            nftId: observable,
            nftUrl: observable,
            nftPopup: observable,
            nftDescription: observable,
            nftName: observable,
            nftPrice: observable,
            nftIsActive: observable,
            links: observable,
            imageSrc: observable,
            imageFile: observable,
            isLoadedModel: observable,
            dropsValue: observable,
            descriptionValue: observable,
            priceValue: observable,
            priceType: observable,
            toggle: observable,
            owner: observable,
            balance: observable,
            creator: observable,
            priceList: observable,
            isSaleable: observable,
            external_id: observable,
            nftHistory: observable,
            showNftHistory: observable,
            ftnNftHistory: observable,
            selectedCerebrumNft: observable,
            metamaskAddress: observable,
            walletProvider: observable,
            contractAddress: observable,
            onZoomNFT: action.bound,
            setNftId: action.bound,
            setNftUrl: action.bound,
            setNftPopup: action.bound,
            setDescription: action.bound,
            setNftName: action.bound,
            setNftPrice: action.bound,
            setNftIsActive: action.bound,
            setLinks: action.bound,
            setImageSrc: action.bound,
            setImageFile: action.bound,
            setIsLoadedModel: action.bound,
            setDropsValue: action.bound,
            setDescriptionValue: action.bound,
            setPriceValue: action.bound,
            setPriceType: action.bound,
            setToggle: action.bound,
            setOwner: action.bound,
            setCreator: action.bound,
            getValidationProperties: action.bound,
            validateForm: action.bound,
            clearNftData: action.bound,
            setPriceList: action.bound,
            setIsSaleable: action.bound,
            setBlockchain: action.bound,
            setTokenStandard: action.bound,
            setContractAddress: action.bound,
            setNftHistory: action.bound,
            setShowNftHistory: action.bound,
            getNftExternal: action.bound,
            setSelectedCerebrumNft: action.bound,
            setMetamaskAddress: action.bound,
            setWalletProvider: action.bound,
            getNftHistory: action.bound,
            setBalance: action.bound
        });
    }

    getNftHistory = async () => {
        const {data: {data: nftHistory}} = await apiService.getNftHistory("");
        this.ftnNftHistory = nftHistory;
    };

    setBalance(str) {
        this.balance = str;
    }

    onZoomNFT = async (data) => {
        const nftData = JSON.parse(data);
        if (nftData?.external_id) {
            this.external_id = nftData?.external_id;
            const {data: {data: nftInfo}} = await apiService.getNftInfo(nftData?.external_id);

            const {
                price,
                is_saleable,
                created_by: {user_name: creatorName, logo_url: creatorImg},
                owned_by: {user_name: ownerName, logo_url: ownerImg},
                media: {
                    original
                },
                meta_title,
                meta_description,
                currency,
                blockchain,
                token_standard,
                contract_address
            } = nftInfo;
            this.setImageSrc(original);
            this.setCreator({
                name: creatorName,
                logo: creatorImg,
            });
            this.setOwner({
                name: ownerName,
                logo: ownerImg,
            });
            this.setNftName(meta_title);
            this.setPriceType(currency ? currency : "AMD");
            this.setDescription(meta_description);
            // this.setLinks([url]);
            this.setIsSaleable(Boolean(is_saleable));
            this.setNftPrice(price);
            this.setBlockchain(blockchain);
            this.setTokenStandard(token_standard);
            this.setContractAddress(contract_address);
            this.setNftPopup(true);
        } else {
            const {nft_file_url, name, description, url, price, owner, creator, currency} = nftData;
            this.setImageSrc(nft_file_url);
            this.setCreator(creator);
            this.setOwner(owner);
            this.setNftName(name);
            this.setNftPopup(true);
            this.setNftPrice(price);
            this.setPriceType(currency ? currency : "AMD");
            this.setDescription(description);
            this.setLinks([url]);
            this.setIsSaleable(true);
        }
        keyboardLockStore.setKeyboardLocked(false);
    };

    setSelectedCerebrumNft(number) {
        this.selectedCerebrumNft = number;
    }

    setIsSaleable = (bool) => {
        this.isSaleable = bool;
    };

    setNftId = (int) => {
        this.nftId = int;
    };

    setNftUrl = (string) => {
        this.nftUrl = string;
    };

    setNftPopup = (bool) => {
        this.nftPopup = bool;
    };

    setDescription = (string) => {
        this.nftDescription = string;
    };

    setNftName = (string) => {
        this.nftName = string;
    };

    setNftPrice = (int) => {
        this.nftPrice = int;
    };

    setNftIsActive = (bool) => {
        this.nftIsActive = bool;
    };

    setLinks = (str) => {
        this.links = str;
    };

    setImageSrc = (img) => {
        this.imageSrc = img;
    };

    setImageFile = (file) => {
        this.imageFile = file;
    };

    setIsLoadedModel = (bool) => {
        this.isLoadedModel = bool;
    };

    setDropsValue = (string) => {
        this.dropsValue = string;
    };

    setDescriptionValue = (string) => {
        this.descriptionValue = string;
    };

    setPriceValue = (string) => {
        this.priceValue = string;
    };

    setPriceType = (string) => {
        this.priceType = string;
    };

    setToggle = (bool) => {
        this.toggle = bool;
    };

    setOwner = (string) => {
        this.owner = string;
    };

    setCreator = (string) => {
        this.creator = string;
    };

    setBlockchain = (string) => {
        this.blockchain = string;
    };

    setTokenStandard = (string) => {
        this.tokenStandard = string;
    };

    setContractAddress = (string) => {
        this.contractAddress = string;
    };

    setMetamaskAddress = (str) => {
        this.metamaskAddress = str;
    };

    getValidationProperties = () => {
        return {
            dropsValue: this.dropsValue,
            descriptionValue: this.descriptionValue,
            imageFile: this.imageFile,
            links: this.links,
            priceValue: this.priceValue
        };
    };

    validateForm = () => {
        const form = this.getValidationProperties();
        let isValid = true;
        Object.keys(form).forEach(item => {
            let error = validateFormKeys(item, form[item]);
            if (error) {
                isValid = false;
                errorStore.setError({key: error});
            }
        });
        return isValid;
    };

    setPriceList = (arr) => {
        this.priceList = arr;
    };

    setNftHistory = (arr) => {
        this.nftHistory = arr;
    };

    setShowNftHistory = (bool) => {
        this.showNftHistory = bool;
    };

    getNftExternal = () => {
        return this.external_id;
    };

    setWalletProvider = (bool) => {
        this.walletProvider = bool;
    };

    clearNftData = () => {
        this.nftIsActive = false;
        this.nftId = 0;
        this.nftUrl = "";
        this.nftPopup = false;
        this.nftDescription = "";
        this.nftName = "";
        this.nftPrice = "";
        this.links = "";
        this.imageSrc = uploadPlaceholder;
        this.imageFile = null;
        this.isLoadedModel = false;
        this.dropsValue = "";
        this.descriptionValue = "";
        this.priceValue = "";
        this.priceType = "USD";
        this.toggle = false;
        this.owner = "";
        this.creator = "";
    };
}

export const nftStore = new NftStore();
