const WarningSvg = () => {
    return (
        <svg width="3.2rem" height="3.2rem" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="8" fill="#FFF6E6"/>
            <g clip-path="url(#clip0_2764_37459)">
                <circle cx="16" cy="16" r="8.33333" stroke="#FEA800" stroke-width="1.5"/>
                <path d="M16 11.8334V16.8334" stroke="#FEA800" stroke-width="1.5" stroke-linecap="round"/>
                <ellipse cx="16" cy="19.3333" rx="0.833333" ry="0.833333" fill="#FEA800"/>
            </g>
            <defs>
                <clipPath id="clip0_2764_37459">
                    <rect width="20" height="20" fill="white" transform="translate(6 6)"/>
                </clipPath>
            </defs>
        </svg>

    )
}

export default WarningSvg;