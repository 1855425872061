import MetaMaskSDK from "@metamask/sdk";
import { createContext } from "react";

export const MetaMaskContext = createContext();
const sdk = new MetaMaskSDK({
    dappMetadata: {
        name: "Fastexverse",
        url: window.location.href,
    },
    extensionOnly: true,
    // preferDesktop: true
});

const MetaMaskContextProvider = ({children})=> {
    return (
        <MetaMaskContext.Provider value={{sdk}}>
            {children}
        </MetaMaskContext.Provider>
    )
}

export default MetaMaskContextProvider