class CacheService {

    get(key) {
        try {
            return localStorage.getItem(key);
        } catch (err) {
            console.log('Local Storage err == ', err);
        }
    }

    getAuthToken() {
        try {
            return localStorage.getItem('Authorization')?.split('Token')[1]?.trim()
        } catch (err) {
            console.log('Local Storage err == ', err);
        }
    }

    set(key, value) {
        try {
            if (!value) {
                localStorage.setItem(key, '');
                return;
            }

            localStorage.setItem(key, value);
        } catch (err) {
            console.log('Local Storage err == ', err);
        }
    }

    clear() {
        localStorage.clear();
    }

    get getMyId() {
        return Number(localStorage.getItem('Id'))
    }

    get isGuest() {
        return localStorage.getItem('UserType') === 'guest'
    }

    get getRoomId() {
        return Number(localStorage.getItem('RoomId'))
    }

    remove(keys = []) {
        keys.forEach(el => {
            localStorage.removeItem(el);
        })
    }
}

export const cacheService = new CacheService();
