const SuccessSvg = () => {
    return (
        <svg width="3.2rem" height="3.2rem" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="-0.00616455" width="32" height="32" rx="8" fill="#E7F8E6"/>
            <g clipPath="url(#clip0_2764_37434)">
                <circle cx="16" cy="15.9939" r="8.33333" stroke="#0CBD00" strokeWidth="1.5"/>
                <path d="M13.0833 16.4105L14.75 18.0772L18.9166 13.9105" stroke="#0CBD00" strokeWidth="1.5"
                      strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_2764_37434">
                    <rect width="20" height="20" fill="white" transform="translate(6 5.99384)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default SuccessSvg