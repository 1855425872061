import {useState} from "react";

import PopupContainer from "../PopupContainer";

import styles from "./blurredBackgroundPopup.module.css";

const BlurredBackgroundPopup = ({children, revertColor = false, action, className = "", revert}) => {
    const [showPopup, setShowPopup] = useState(true);
    const onClose = () => {
        setShowPopup(false);
        setTimeout(() => {
            action();
        }, 200)
    }
    return (
        <div
            className={`${styles.blurredBackgroundPopup} ${!showPopup ? styles.closed : ""} ${revertColor ? styles.revertColors : ""}`}>
            <PopupContainer action={onClose} revert={revert} className={className}>
                {children}
            </PopupContainer>
        </div>
    )
}

export default BlurredBackgroundPopup;