import { makeObservable, observable, action } from "mobx";
import {dashboardStore} from "./dashboardStore";

class AuthStore {
    slide = 0; // Authorization Slide percentage
    email = ""; // user email
    password = ""; // user pass
    new_password1 = ""; // user create pass on signUp | forgot pass
    new_password2 = ""; // user create pass on NftConfirm | forgot pass
    nickname = ""; // user create nickname on signUp
    isUser = true; // is actual user
    notification = false; // user check in signUp
    forgot = false; // user forgot pass flag
    public_id = -1;
    doubleLogin = false;
    success = undefined;
    inviteMessage = "";
    deletePopup = false;
    deleteReasons = [];
    termsPopup = "";
    token = "";
    checkToken = false;
    inviteMessageUrlMetaMask = "";
    hooryValue = "";
    avatarImage = "";
    provider = "";
    connection_id = "";
    appleData = {};

    constructor() {
        makeObservable(this, {
            slide: observable,
            email: observable,
            password: observable,
            isUser: observable,
            nickname: observable,
            forgot: observable,
            notification: observable,
            public_id: observable,
            doubleLogin: observable,
            success: observable,
            inviteMessage: observable,
            deletePopup: observable,
            deleteReasons: observable,
            termsPopup: observable,
            token: observable,
            inviteMessageUrlMetaMask: observable,
            checkToken: observable,
            hooryValue: observable,
            avatarImage: observable,
            provider: observable,
            connection_id: observable,
            appleData: observable,
            setSlide: action.bound,
            setIsUser: action.bound,
            setEmail: action.bound,
            setPassword: action.bound,
            setNewPassword1: action.bound,
            setNewPassword2: action.bound,
            setNotification: action.bound,
            setNickname: action.bound,
            setForgot: action.bound,
            setDoubleLogin: action.bound,
            setSuccess: action.bound,
            setInviteMessage: action.bound,
            setDeletePopup: action.bound,
            setDeleteReasons: action.bound,
            setTermsPopup: action.bound,
            setToken: action.bound,
            setCheckToken: action.bound,
            setInviteMessageUrlMetaMask: action.bound,
            setHooryValue: action.bound,
            setAvatarImage: action.bound,
            setProvider: action.bound,
            setConnection_id: action.bound,
            setAppleData: action.bound,
        });
    }

  setConnection_id(string) {
      this.connection_id = string;
  }

  setEmail(string) {
    this.email = string;
  }

  setPassword(string) {
    this.password = string;
  }

  setNewPassword1(string) {
    this.new_password1 = string;
  }

  setNewPassword2(string) {
    this.new_password2 = string;
  }

  setNickname(string) {
    this.nickname = string;
  }

  setNotification(flag) {
    this.notification = flag;
  }

  setIsUser(flag) {
    this.isUser = flag;
  }

  setForgot(flag) {
    this.forgot = flag;
  }

  setSlide(number) {
    this.slide = number;
  }

  setPublicId(number) {
    this.public_id = number;
  }

  setClear() {
    this.setSlide(0);
    this.setEmail("");
    this.setPassword("");
  }

  setDoubleLogin(bool) {
    this.doubleLogin = bool;
  }

  setSuccess(bool) {
    this.success = bool;
  }

  setInviteMessage(string) {
    this.inviteMessage = string;

        setTimeout(() => {
            this.inviteMessage = ""
            this.inviteMessageUrlMetaMask = ""
        }, 5000);
    }

  setDeletePopup(bool) {
    this.deletePopup = bool;
    dashboardStore.showSidebar = !bool;
  }

  setDeleteReasons(arr) {
    this.deleteReasons = arr;
  }

  setTermsPopup(str) {
    this.termsPopup = str;
  }

  setToken(token) {
    this.token = token;
  }

    setCheckToken(bool) {
        this.checkToken = bool;
    }

    setInviteMessageUrlMetaMask(str) {
        this.inviteMessageUrlMetaMask = str;
    }

    setHooryValue(str) {
        this.hooryValue = str;
    }

    setAvatarImage(str) {
        this.avatarImage = str;
    }

    setProvider(str) {
        this.provider = str;
    }

    setAppleData(obj) {
        this.appleData = obj;
    }
    
}

export const authStore = new AuthStore();
