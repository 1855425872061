import style from "./svgJs.module.scss";

const CopyTextSvg = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 7.33301C4 5.44739 4 4.50458 4.58579 3.91879C5.17157 3.33301 6.11438 3.33301 8 3.33301H10C11.8856 3.33301 12.8284 3.33301 13.4142 3.91879C14 4.50458 14 5.44739 14 7.33301V10.6663C14 12.552 14 13.4948 13.4142 14.0806C12.8284 14.6663 11.8856 14.6663 10 14.6663H8C6.11438 14.6663 5.17157 14.6663 4.58579 14.0806C4 13.4948 4 12.552 4 10.6663V7.33301Z"
                className={style.stroke} strokeWidth="1.5"/>
            <path
                d="M4 12.6663C2.89543 12.6663 2 11.7709 2 10.6663V6.66634C2 4.15218 2 2.89511 2.78105 2.11406C3.5621 1.33301 4.81918 1.33301 7.33333 1.33301H10C11.1046 1.33301 12 2.22844 12 3.33301"
                className={style.stroke} strokeWidth="1.5"/>
        </svg>

    )
}

export default CopyTextSvg;