import {modifyKickOfUserOptions} from "../helpers/modifyErrorData";
import {participantsStore} from "./participantsStore";
import {alternativeGuest} from "../helpers/alternativeGuest";
// import {cacheService} from "../services/cacheService";

const {setBlockedUser} = participantsStore;

export const BUILD_URL = {
    dataUrl: 'https://space-api.pandamr.io/media/dynamic/unity_files/215WebGl.data.br',
    frameworkUrl: 'https://space-api.pandamr.io/media/dynamic/unity_files/215WebGl.framework.js.br',
    loaderUrl: 'https://space-api.pandamr.io/media/dynamic/unity_files/215WebGl.loader.js',
    codeUrl: 'https://space-api.pandamr.io/media/dynamic/unity_files/215WebGl.wasm.br',
};
export const OTP_INITIAL_VALUE = [false, false, false, false, false, false];

export const PRIVATE_CALL_STATUS = {
    0: "None",
    1: "Invited",
    2: "Connected",
    3: "Accepted",
    4: "Declined",
};
export const MEDIA_DEVICE_STATUS = {
    enabled: "enabled",
    disabled: "disabled",
    notFound: "notFound",
};

export const WARNING_TYPES = {
    ERROR: "error",
    WARNING: "warning",
    INFO: "info",
    SUCCESS: "success",
    MESSAGE: "message",
};

export const MICROPHONE_STATUS = {
    0: "MUTE",
    1: "UNMUTE",
    2: "FREEZE",
    3: "MUTE_ALL",
};

export const SELECT_OPTION = [
    {
        value: 2,
        visibleMinutes: 2,
        translationName: "minutes"
    },
    {
        value: 10,
        visibleMinutes: 10,
        translationName: "minutes"
    },
    {
        value: 30,
        visibleMinutes: 30,
        translationName: "minutes"
    },
    {
        value: 60,
        visibleMinutes: 1,
        translationName: "hours"
    },
    {
        value: 1440,
        visibleMinutes: 24,
        translationName: "hours"
    }
];

export const ERROR_MAP = {
    "expired_session": {
        redirectPath: "/",
    },
    "guest_does_not_have_permission": {
        modal_title: "“Oops!”",
        modal_message: "Guest does not have permission to perform this action.",
        isModal: true,
        closeable: true,
        callBack: () => {
            setBlockedUser(true);
        },
    },
    "permission_denied": {
        redirectPath: "/dashboard",
    },
    "blocked_user_exception": {
        modal_title: "Blocked",
        isModal: true,
        closeable: true,
        callBack: () => {
            setBlockedUser(true);
        }
    },
    "please_sing_in_sign_up": {
        modal_title: "want_to_continue",
        isModal: true,
        closeable: true,
        actionBtnOk: true,
        redirectPath: "/dashboard",
        actionBtnName: ["sign_in", "sign_up"],
        actionBtnLink: ["/", "/"],
        actionBtnNavigate: () => alternativeGuest(),
        // callBack: () => {
        //     setBlockedUser(true);
        // }
    },
    "user_deactivated": {
        redirectPath: "/",
    },
    "invalid_credentials": {
        redirectPath: "/",
    },
    "email_not_confirm": {
        redirectPath: "/expired-link",
    },
    "user_does_not_have_permission": {
        modal_title: "“Oops!”",
        isModal: true,
        closeable: true,
        callBack: () => {
            setBlockedUser(true);
        }
    },
    "admin_has_blocked": {
        modal_title: "Oops, Blocked!",
        sendedText: "",
        isModal: true,
        closeable: true,
        callBack: () => {
            setBlockedUser(true);
        }
    },
    "admin_has_kick_off": {
        modal_title: "You are Kicked Off",
        sendedText: "",
        isModal: true,
        closeable: true,
        callBack: () => {
            setBlockedUser(true);
        }
    },
    'unity_doesnt_support': {
        modal_title: '“Oops!”',
        isModal: true,
        closeable: true,
        redirectPath: '/dashboard',
    },
    "room_does_not_exist": {
        redirectPath: "/404",
    },
    "connection_lost": {
        modal_title: "Connection was lost!",
        isModal: true,
        connected: false,
        closeable: true,
    },
    "connection_slow": {
        modal_title: 'Slow internet connection',
        isModal: true,
        connected: false,
        closeable: true,
    },
    "user_freezed": {
        modal_title: "Muted",
        isModal: true,
        closeable: true,
        actionBtnOk: true
    },
    "private_call": {
        modal_title: "Private Call",
        isModal: true,
        closeable: true,
        actionBtnOk: true
    },
    404: {
        redirectPath: "/maintenance",
    }
};

export const modifyErrorData = (options) => {
    const data = {
        "admin_has_blocked": modifyKickOfUserOptions,
        "admin_has_kick_off": modifyKickOfUserOptions,
    }
    return data[options.key] ? {...data[options.key](options)} : {}
}

export const WARNING_OPTIONS = {
    please_login_marketplace: {
        type: WARNING_TYPES.INFO,
    },
    audio_device: {
        type: WARNING_TYPES.ERROR,
    },
    video_device: {
        type: WARNING_TYPES.ERROR,
    },
    empty_ecommerce: {
        type: WARNING_TYPES.WARNING,
    },
    something_went_wrong: {
        type: WARNING_TYPES.WARNING,
    },
    nft_already_owned: {
        type: WARNING_TYPES.WARNING,
    },
    your_purchase_successful: {
        type: WARNING_TYPES.SUCCESS,
    },
    session_expired: {
        type: WARNING_TYPES.ERROR,
    },
    500: {
        type: WARNING_TYPES.ERROR,
        redirectPath: "/maintenance",

    },
    404: {
        type: WARNING_TYPES.ERROR,
        redirectPath: "/maintenance",
    },
    connection_slow: {
        type: WARNING_TYPES.WARNING,
    },
    microphones_disabled: {
        type: WARNING_TYPES.INFO,
    },
    on_freeze: {
        type: WARNING_TYPES.MESSAGE,
    },
    on_unfreeze: {
        type: WARNING_TYPES.MESSAGE,
    },
    on_admin: {
        type: WARNING_TYPES.MESSAGE,
    },
    on_remove_admin: {
        type: WARNING_TYPES.MESSAGE,
    }
}
