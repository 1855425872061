import { useCallback, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { errorStore } from '../../store/errorStore'
import { ReactComponent as CloseIco } from '../../assets/icons/close.svg'
import { ReactComponent as ConnectionLost } from '../../assets/icons/connection-lost.svg'
import { ReactComponent as InternetConnected } from '../../assets/icons/internet-connected.svg'
import { translationStore } from '../../store/translationStore'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import WarningPopup from './WarningPopup'
import { useNavigate } from 'react-router-dom'
import {cacheService} from "../../services/cacheService";
import { participantsStore } from '../../store/participantsStore'
import './index.scss'

const ErrorModal = observer(() => {
    const modalRef = useRef();
    const { closeModal, hasOnModal, modalMessage, closeable, modalTitle, connected, actionBtnOk, errorOptions } = errorStore;
    const {setBlockedUser} = participantsStore;
    const [timer, setTimer] = useState({});

    const timeObjectToSeconds = useCallback( ({ hours = 0, minutes = 0, seconds = 0 }) =>
    { return hours * 3600 + minutes * 60 + seconds }, [errorOptions?.options]);


    useEffect(()=> {
        if(errorOptions?.options) {
            let totalSeconds = timeObjectToSeconds(errorOptions.options || {})
            const secondsToTimeObject = totalSeconds => {
             if(totalSeconds <= 0) return 
                const hours = Math.floor(totalSeconds / 3600);
                const minutes = Math.floor((totalSeconds % 3600) / 60);
                const seconds = totalSeconds % 60;
                return { hours, minutes, seconds };
            };
     
            const timerHandler = () => {
                if(totalSeconds > 1) { setTimer(secondsToTimeObject(--totalSeconds)) }
                else{ 
                    clearInterval(timerHandler);
                    onCloseModal()
                }
            }
            setInterval(timerHandler, 1000);
        }
    }, [errorOptions])


    const { translationData } = translationStore;

    const navigate = useNavigate()

    const onAcceptBtnAction = (index) => {
        if(errorOptions?.actionBtnNavigate) {
            errorOptions.actionBtnNavigate();
            navigate(errorOptions?.actionBtnLink[index])
        }
        closeModal();
    }

    const onCloseModal = () => {
        cacheService.remove(["AlternateGuest"])
        setBlockedUser(false);
        closeModal();
    }

    useOnClickOutside(modalRef, onCloseModal);

    return (
        <>
            {hasOnModal && (
                <div className={`error_popup_holder ${!closeable ? 'fullscreen' : ''}`}>
                    <div ref={modalRef} className={`error_modal connection_modal`}>
                        <div className="modal_body">
                            <div className="modal_title">
                                <div></div>
                                <div className="title_text">{translationData?.[modalTitle] || modalTitle}</div>
                                <div>{closeable && <CloseIco className="error_ico" onClick={onCloseModal} />}</div>
                            </div>
                            {connected === undefined ? null : connected ? (
                                <InternetConnected />
                            ) : (
                                <ConnectionLost className="connection_lost" />
                            )}
                            <div className="modal_text">
                                <span>{translationData?.[modalMessage]}</span>
                                {
                                    Object.keys(timer).map((item, i)=> {
                                        if(timer[item] > 0) return <span key={i}>{` ${timer[item]}`} {translationData?.[item]}</span>
                                        
                                    })
                                }
                            </div>
                            <div className="error_btns">
                                {actionBtnOk && errorOptions?.actionBtnName ? errorOptions?.actionBtnName.map((actionBtn, index) => (
                                    <button key={index} className="ok_btn" onClick={() => onAcceptBtnAction(index)}>
                                        {translationData?.[actionBtn || "ok"]}
                                    </button>
                                )) : <button className="ok_btn" onClick={onAcceptBtnAction}>
                                    {translationData?.["ok"]}
                                </button>}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <WarningPopup/>
        </>
    )
})

export default ErrorModal
