import { useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { participantsStore } from '../../../../store/participantsStore';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import { ReactComponent as CloseIco } from "../../../../assets/icons/close.svg";
import { translationStore } from '../../../../store/translationStore';
import './styles.scss';

const MakeAdminModal = observer(()=> {
    const ref = useRef()
    const {setMakeAdmin, makeAdminHandler, userIsAdmin, removeAdminRole, user} = participantsStore;
    const {translationData} = translationStore;

    useOnClickOutside(ref, ()=>setMakeAdmin(false));
    const isAdmin = useMemo(()=> userIsAdmin(user.publicId), [user])
    const userRoleHandler = ()=> {
        isAdmin ? removeAdminRole(user.publicId) : makeAdminHandler()
    }
    return (
        <div ref={ref} className='make_admin'>
            <div className='header_section'>
                <div></div>
                <p className='modal_name'>
                    {
                        isAdmin ? (
                            translationData?.["make_user"] || "Make User"
                        ) :
                        (
                            translationData?.["make_admin"] || "Make Admin"
                        )
                    }
                </p>
                <CloseIco className='close_popup' onClick={()=>setMakeAdmin(false)}/>
            </div>
            {
                isAdmin ? (
                    <p className='modal_description'>
                        {translationData?.["set_is_user"] || "Are you sure you want to make"}
                        {` ${user.name} ?`}
                    </p>
                )
                :
                (
                    <p className='modal_description'>
                        {translationData?.["set_is_admin"] || "Are you sure you want to make"}
                        {` ${user.name} ?`}
                    </p>
                )
            }
            <div className='buttons_section'>
                <div><button className='action_btn' onClick={()=>setMakeAdmin(false)}>{translationData?.["cancel"] || "Cancel"}</button></div>
                <div>
                    <button className='action_btn block' onClick={userRoleHandler}>
                        {
                            isAdmin ? (
                                translationData?.["make_user"] || "Make User"
                            ) :
                            (
                                translationData?.["make_admin"] || "Make Admin"
                            )
                        }
                    </button>
                </div>
            </div>
        </div>
    )
})

export default MakeAdminModal