import { useEffect } from "react";
import { translationStore } from "../store/translationStore";
import { useSearchParams } from "react-router-dom";
import { Languages } from "../constants";

const useTranslates = () => {
  const { getTranslationData, activeLanguage, setActiveLanguage } =
    translationStore;
  const [searchParams] = useSearchParams();

  useEffect(() => {
    void getTranslates();
  }, [activeLanguage]);

  const getTranslates = async () => {
    const lang = searchParams.get("lang");
    const matchingLanguage = Object.values(Languages).find(
      (language) => language.value === lang
    );

    if (matchingLanguage) {
      setActiveLanguage(matchingLanguage);
    }
    await getTranslationData();
    if(window.$hoory) {
      window.$hoory.setLocale(Languages[activeLanguage]?.value)
    }
  };
};

export default useTranslates;
