import React, {useRef} from "react";
import {translationStore} from "../../store/translationStore";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {observer} from "mobx-react-lite";
import {roomStore} from "../../store/roomStore";

const InvalidTicketPopup = observer(() => {
    const ref = useRef();
    const {translationData} = translationStore;
    const {openInvalidTicketPopup} = roomStore;

    const closePopup = () => {
        openInvalidTicketPopup(false)
    };

    useOnClickOutside(ref, closePopup);

    return (
        <div className="popup-holder">
            <div className="popup-body clear" ref={ref}>
                <i className="icon-close" onClick={closePopup}/>
                <h1 className="popup-body-title">{translationData?.["used_ticket"] || "Used ticket"}</h1>
                <div
                    className="popup-body-text">{translationData?.["invalid_ticket"] || "The ticket is already used! Please make sure to enter the room with the appropriate account."}</div>
                <div className="popup-buttons">
                <button onClick={closePopup} className="pop-but leave"
                        type="button">{translationData?.["ok"] || "OK"}</button>
                </div>
            </div>
        </div>
    )
})

export default InvalidTicketPopup;