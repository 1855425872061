import React, {useEffect} from "react";
import {cacheService} from "../services/cacheService";
import {authStore} from "../store/authStore";
import {observer} from "mobx-react-lite";
import useWebSocket from "react-use-websocket";
import {errorStore} from "../store/errorStore";
import {dashboardStore} from "../store/dashboardStore";
import { participantsStore } from "../store/participantsStore";
import {unityLayoutStore} from "../store/unityLayoutStore";

export const Hoc = (Component) => {
    return observer((props) => {
        const Init = () => {
            const token = cacheService.getAuthToken() || ' '

            const options = {
                reconnectAttempts: 10,
                reconnectInterval: 1000,
                retryOnError: true,
                onError: (e) => console.log('WebSocket ERROR: ', e),
            }

            const webSocketUrl = process.env.REACT_APP_WEBSOCKET_URL + token.trim()

            const {lastMessage} = useWebSocket(webSocketUrl, options) ?? false;
            const {setDoubleLogin, setInviteMessage, setConnection_id, setAvatarImage} = authStore;
            const {setError, reconnect_internet} = errorStore;
            const {setLikedRoom, setOnlineUsersCount, setInviteData, endCountDown} = dashboardStore;
            const {setAdminUsers, isAdminRole} = participantsStore;
            const {setRoomStatus} = unityLayoutStore;
            const {setWarning} = errorStore;

            useEffect(() => {
                if (lastMessage) {
                    const {data: {command, response}} = JSON.parse(lastMessage.data);
                    switch (command) {
                        // case "double_login":
                        //     setDoubleLogin(true);
                        //     break;
                        case "update_online_users_count":
                            setOnlineUsersCount(response.data);
                            break;
                        case "add_or_remove_admin_role":
                            if (Number(response.data.room_public_id) === Number(cacheService.get("RoomId"))) {
                                const condidate = response.data.admins.filter(user => user.public_id === cacheService.getMyId)[0];
                                if(isAdminRole && !condidate){
                                    setWarning('on_remove_admin')
                                }
                                if(!isAdminRole && condidate){
                                    setWarning('on_admin')
                                }
                                setAdminUsers(response.data.admins);
                            }
                            break;
                        case "user_room_invite":
                            setInviteMessage(response.data.message);
                            break;
                        case "likes_total_count":
                            setLikedRoom(response);
                            break;
                        case "invite_home_space":
                            setInviteData(response.data);
                            break;
                        case "change_room_state":
                            setRoomStatus(response.data);
                            break;
                        case "change_room_event_state":
                            const {live_event_start, room_original_name, room, live_event_state} = response;
                            endCountDown({
                                room_name: room_original_name,
                                public_id: room,
                                is_live_event: live_event_state,
                                live_event_data: { start: live_event_start },
                            })
                            break
                        case 'event_start_websocket':
                            setInviteMessage(response.data.message)
                            break;
                        case 'double_login':
                            // console.log(response.connection_id, "response.data.message", cacheService.get("socket_session_id"));
                            // setConnection_id(response.connection_id)
                            //
                            // if(response.connection_id?.toString() === cacheService.get("socket_session_id")?.toString()) {
                                setDoubleLogin(true);
                            // }
                            break;
                        case 'update_avatar_image_url':
                            setAvatarImage(response.data)
                            break;
                    }
                }

            }, [lastMessage]);

            useEffect(() => {
                window.addEventListener("online", reconnect_internet);
                window.addEventListener("offline", () => setError({key:"connection_lost"}));

                window.addEventListener('offline', () => setError({ key: 'connection_lost' }))
                return () => {
                    window.removeEventListener('online', reconnect_internet)
                    window.removeEventListener('offline', setError)
                }
            }, [])
        }

        Init()

        return <Component {...props} />
    })
}
