import {makeAutoObservable} from "mobx";

class Web3Store {
    isConnected = false;
    account = "";
    chainId = ""
    balance = ""
    popupToggle = false

    setAccount = (str) => {
        this.account = str;
    }

    setIsConnected = (bool) => {
        this.isConnected = bool;
    }

    setChainId = (str) => {
        this.chainId = str;
    }

    setBalance = (str) => {
        this.balance = str;
    }

    setPopupToggle = (bool) => {
        this.popupToggle = bool;
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export const web3Store = new Web3Store();
