import React, {useRef} from "react";
import {observer} from "mobx-react-lite";
import {translationStore} from "../../store/translationStore";
import useOnClickOutside from "../../hooks/useOnClickOutside";

const InviteToHomeSpace = observer(({user, roomLink, clearData}) => {
    const {translationData} = translationStore;
    const ref = useRef();

    const closePopup = () => {
        clearData();
    };

    useOnClickOutside(ref, closePopup);

    const goToInvitedRoom = () => {
        window.location.assign(roomLink);
        closePopup();
    }

    return (
        <div className="popup-holder">
            <div className="popup-body leave" ref={ref}>
                <i className="icon-close" onClick={closePopup}/>
                <h1 className="popup-body-text">{user} {translationData?.["has_invited_you"] || "has invited you to"} {translationData?.["home_space"] || "Home Space"}</h1>
                <div className="popup-buttons">
                    <button onClick={closePopup} className="pop-but leave"
                            type="button">{translationData?.["decline"] || "Decline"}</button>
                    <button onClick={goToInvitedRoom} className="pop-but"
                            type="button">{translationData?.["accept"] || "Accept"}</button>
                </div>
            </div>
        </div>
    )
});

export default InviteToHomeSpace;