import {action, computed, makeObservable, observable} from "mobx";

class PrivateTalkStore {
    invitedUser = 0;
    openUserMenu = false;
    inviteToAgoraChannel = false;
    privateTalkCreated = false;
    privateChannelName = null;
    participantPopup = false;
    agoraToken = null;
    senderUserId = null;
    callCreatorId = 0;

    constructor() {
        makeObservable(this, {
            invitedUser: observable,
            openUserMenu: observable,
            inviteToAgoraChannel: observable,
            privateTalkCreated: observable,
            privateChannelName: observable,
            participantPopup: observable,
            agoraToken: observable,
            callCreatorId: observable,
            setInvitedUser: action.bound,
            setOpenUserMenu: action.bound,
            setInviteToAgoraChannel: action.bound,
            setPrivateTalkCreated: action.bound,
            hasPrivateTalkCreated: action.bound,
            setChannelName: action.bound,
            setParticipantPopup: action.bound,
            setAgoraToken: action.bound,
            clearPrivateTalkData: action.bound,
            setSenderUser: action.bound,
            setCallCreatorId: action.bound,
            getPrivateCreatorId: action.bound,
            hasPrivateCall: computed
        });
    }

    setInvitedUser(num) {
        this.invitedUser = num;
    }

    setOpenUserMenu(bool) {
        this.openUserMenu = bool;
    }

    setInviteToAgoraChannel(bool) {
        this.inviteToAgoraChannel = bool;
    }

    setPrivateTalkCreated(bool) {
        this.privateTalkCreated = bool;
    }

    hasPrivateTalkCreated() {
        return this.privateTalkCreated;
    }

    setChannelName(str) {
        this.privateChannelName = str;
    }

    setParticipantPopup(bool) {
        this.participantPopup = bool;
    }

    setAgoraToken(str) {
        this.agoraToken = str;
    }

    get hasPrivateCall() {
        return !!this.privateChannelName;
    }

    setSenderUser(id){
        this.senderUserId = id;
    }

    setCallCreatorId(id){
        this.callCreatorId = id;
    }

    getPrivateCreatorId(){
        return this.callCreatorId;
    }

    clearPrivateTalkData() {
        this.invitedUser = 0;
        this.openUserMenu = false;
        this.inviteToAgoraChannel = false;
        this.privateTalkCreated = false;
        this.privateChannelName = null;
        this.participantPopup = false;
        this.agoraToken = null;
        this.senderUserId = null;
        this.callCreatorId = 0;
    }
}

export const privateTalkStore = new PrivateTalkStore();
