import React from "react";
import style from "./svgJs.module.scss";
const FastexSpacesSvg = ({active = true, onlyWhite = false}) => {
    return (
        <svg width="2rem" height="2rem" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.3334 0.354707C11.4576 -0.118236 8.53329 -0.118236 5.65752 0.354707C4.32277 0.572988 3.09722 1.20358 2.15076 2.16159C1.19217 3.1196 0.573335 4.3444 0.354922 5.66621C-0.118307 8.54024 -0.118307 11.4749 0.354922 14.3489C0.573335 15.6829 1.2043 16.9077 2.15076 17.8536C3.09722 18.8116 4.3349 19.4422 5.65752 19.6604C7.08934 19.8909 8.54542 20 10.0015 20C11.4576 20 12.9016 19.8787 14.3455 19.6362C15.6803 19.4179 16.9058 18.7873 17.8523 17.8293C18.8109 16.8713 19.4297 15.6465 19.6481 14.3247C19.8787 12.8937 20 11.4507 20 9.99545C20 8.54025 19.8787 7.08504 19.636 5.65409C19.4176 4.33227 18.7866 3.10747 17.8401 2.16159C16.8937 1.21571 15.6681 0.585115 14.3455 0.366834L14.3334 0.354707ZM8.02366 16.6045C7.76884 16.6045 7.51403 16.4954 7.33202 16.3135C6.99226 15.9861 6.95586 15.4404 7.22281 15.0402L9.6739 11.6204L5.60898 11.2324C5.17215 11.196 4.796 10.905 4.66252 10.4805C4.51691 10.0561 4.63826 9.6074 4.96588 9.30423L11.2999 3.62892C11.4819 3.47128 11.7124 3.37426 11.9672 3.37426C12.2342 3.37426 12.4769 3.4834 12.6589 3.6653C12.9986 3.99273 13.035 4.53843 12.756 4.92648L10.3049 8.34622L14.3698 8.73427C14.8187 8.77065 15.1828 9.07382 15.3284 9.49825C15.474 9.92269 15.3526 10.3714 15.025 10.6745L8.69104 16.3499C8.52116 16.5075 8.27848 16.6045 8.02366 16.6045Z"
                className={active ? style.fillActive : onlyWhite ? style.onlyWhiteFill : style.fill} style={{transition: "all ease 0.3s"}}/>
        </svg>
    )
}

export default FastexSpacesSvg;