import {useEffect, useRef} from "react";
import gsap from "gsap";

import {SuccessSvg, WarningSvg, RejectSvg} from "../assets/svgJs/notificationIcons"

import styles from "./notification.module.css";

const notificationData = {
    "success": {
        Icon: SuccessSvg,
        className: styles.success
    },
    "warning":{
        Icon: WarningSvg,
        className: styles.warning
    },
    "error":{
        Icon: RejectSvg,
        className: styles.reject
    }
};

const Notification = ({type = "success", text, hideNotification}) => {
    const ref = useRef();

    useEffect(() => {
        if(!ref.current) return;
        const tl = gsap.fromTo(ref.current,
            {
                y: "12rem",
                opacity: 0,
            }, {
                y: "-12rem",
                opacity: 1,
                duration: 1.5,
                ease: "power3",
                onComplete: () => {
                    setTimeout(() => {
                        tl.reverse();
                    }, 2000)
                    setTimeout(() => {
                        hideNotification();
                    }, 3500)
                }
            });
    }, [hideNotification])

    const {Icon, className} = notificationData[type];

    return (
        <div ref={ref} className={`${styles.notificationContainer} ${className}`}>
            <Icon />
            <p className={`${styles.notificationText} ${className}`}>{text}</p>
            {/* <span className={`${styles.closeIcon} ${className}`}/> */}
        </div>
    )
}

export default Notification;