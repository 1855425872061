import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Navigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import routes from './routes'
import { unityStore } from './store/unityStore'
import { authStore } from './store/authStore'
import { dashboardStore } from './store/dashboardStore'
import DoubleLogin from './components/Popups/DoubleLogin'
import InviteShowPopup from './components/Popups/InviteShowPopup'
import ErrorModal from './components/ModalError'
import Mobile from './containers/mobile'
import ParticipantsPopup from './components/Popups/ParticipantsPopup'
import InviteToHomeSpace from './components/Popups/InviteToHomeSpace'
import TransitionRouter from "./components/TransitionRouter";
import useRotate from "./hooks/useRotate";
import {roomStore} from "./store/roomStore";
import InvalidTicketPopup from "./components/Popups/InvalidTicketPopup";
import MetaMaskPopup from "./components/Metamask/MetaMaskPopup";
import {web3Store} from "./store/web3Store";
import TanstackProvider from "./components/Metamask/TanstackProvider";
import WalletPopup from "./components/Popups/WalletPopup";
import CustomSwitch from './ui/CustomSwitch'
import RotateDevice from './components/RotateDevice'
import { notificationStore } from './store/notificationStore'
import Notification from './ui/Notification'
import MetaMaskContextProvider from "./Providers/MetaMaskProvider";
import { nftStore } from './store/nftStore'
import CerebrumNftNew from './components/Metamask/CerebrumNft/CerebrumNftNew'
import TicketUploadGuest from "./ui/Popups/TicketUploadGuest";
import LanguageWrapper from "./ui/LanguageWrapper";

const TicketPopup = lazy(() => import('./components/Popups/TicketPopup'))
const UnityLayout = lazy(() => import('./containers/unityLayout'))

const App = observer(() => {
    const {loadUnity, isActiveMobile} = unityStore;
    const {doubleLogin, inviteMessage} = authStore;
    const {inviteData, clearInviteData, showWalletPopup} = dashboardStore;
    const {notification, clearNotification} = notificationStore;
    const {ticketRoomId, setTicketPopupId, isInvalidTicketPopup, showGuestPopup} = roomStore;
    const {popupToggle} = web3Store;

    const showRotateDevice = useRotate(false);
    const {selectedCerebrumNft} = nftStore


    return (
        <Suspense fallback={null}>
            <TanstackProvider>
            <MetaMaskContextProvider>
                <BrowserRouter>
                    <TransitionRouter>
                        <CustomSwitch>
                            <Route path="/:lang?" element={<LanguageWrapper />}>
                            {routes.map((item, index) => (
                                <Route
                                    key={index}
                                    path={item.path}
                                    element={<item.component/>}
                                    title={item.title}
                                    exact
                                />
                            ))}
                            <Route path="*" element={<Navigate to="/404" replace/>}/>
                            </Route>
                        </CustomSwitch>
                    </TransitionRouter>
                    <Suspense fallback={null}>
                        {loadUnity && !isActiveMobile && <UnityLayout/>}
                        {isActiveMobile && <Mobile/>}
                    </Suspense>
                    <Suspense fallback={null}>
                        {ticketRoomId ? <TicketPopup setToggle={setTicketPopupId} /> : <></>}
                    </Suspense>
                    {doubleLogin && <DoubleLogin/>}
                    {!!inviteMessage && <InviteShowPopup/>}
                    {!!showGuestPopup && <TicketUploadGuest/>}
                    {!!inviteData?.sender_user && (
                        <InviteToHomeSpace
                            user={inviteData?.sender_user}
                            roomLink={inviteData?.link}
                            clearData={clearInviteData}
                        />
                    )}
                    {popupToggle && <MetaMaskPopup/>}
                    <ParticipantsPopup/>
                    <ErrorModal/>
                    {isInvalidTicketPopup && (
                        <InvalidTicketPopup/>
                    )}
                </BrowserRouter>
                </MetaMaskContextProvider>
                {showRotateDevice && <RotateDevice/>}
                {/*<InternetSpeedChecker />*/}
                {/* <Loading/> */}
                {showWalletPopup && <WalletPopup/>}
                {!!notification && <Notification type={notification.type} text={notification.text} hideNotification={clearNotification}/>}
                {!!selectedCerebrumNft && <CerebrumNftNew />}
            </TanstackProvider>
        </Suspense>
    );
});

export default App;
