import React from "react";
import {observer} from "mobx-react-lite";
import { nftStore } from "../../../../store/nftStore";
import NftHistoryList from "./NftHistoryList/NftHistoryList";
import { translationStore } from '../../../../store/translationStore'
import styles from "./index.module.scss";
const CEREBRUM_ADDRESS = "0x1c9356a6599cd8a5cc44eb7c053e6d3b5b52493f";

const InfoContainer = observer(({tab}) => {
    const {
        ftnNftHistory,
    } = nftStore;

    const { translationData } = translationStore

    return (
        <div className={styles.nft_info}>
            {
                tab === "tab1" ? 
                <>
                    <p className={styles.info_header}>Blockchain</p>
                    <div className={styles.blockchain_info}>
                        <div className={styles.row}>
                            <div
                                className={styles.title}>{translationData?.['cerebrum_address'] || 'Cerebrum Address'}</div>
                            <div className={`${styles.value} ${styles.link_with_dots}`}><a target="_blank" href={`https://www.ftnscan.com/token/${CEREBRUM_ADDRESS}`}>{CEREBRUM_ADDRESS}</a></div>
                        </div>
                        <div className={styles.row}>
                            <div
                                className={styles.title}>{translationData?.['token_standard'] || 'Token Standard'}</div>
                            <div className={styles.value}>BHRC-721</div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.title}>{translationData?.['chain'] || 'Chain'}</div>
                            <div className={styles.value}>{translationData?.["BAHAMUT"] || "BAHAMUT"}</div>
                        </div>
                    </div>
                    {/*<p className={styles.info_properties}>{translationData?.['properties'] || 'Properties'}</p>*/}
                </> :
                <NftHistoryList
                    nftHistory={ftnNftHistory}
                />
            }
        </div>
    )
})

export default InfoContainer;
