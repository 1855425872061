import { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { participantsStore } from '../../../../store/participantsStore';
import { ReactComponent as CloseIco } from "../../../../assets/icons/close.svg";
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import { translationStore } from '../../../../store/translationStore';
import Select from './Select/Select';
import './styles.scss';

const KickOffModal = observer(({onKickOff})=> {
    const ref = useRef()
    const [timeRange, setTimeRange] = useState();
    const [error, setError] = useState('');
    const {kickOffModalToggle, user} = participantsStore;
    const {translationData} = translationStore;
    useOnClickOutside(ref, ()=>kickOffModalToggle());

    const timeRangeHandler =(interval) => {
        setTimeRange(interval)
    }
    return (
        <div ref={ref} className='kick_off_modal'>
            <div className='header_section'>
                <div></div>
                <h2 className='mofal_name'>{translationData?.["kick_off_user"] || "Kick off user"}</h2>
                <CloseIco className='close_popup' onClick={()=>kickOffModalToggle()}/>
            </div>
            <p className='modal_description'>
                {translationData?.["kick_off_modal_description_1"]}
                {` ${user.name}`}
                {translationData?.["kick_off_modal_description_2"]}
                {!(translationData?.["kick_off_modal_description_1"]) && "If you kick off the user, they wont be able to enter to this space."}
            </p>
            <div className='modal_body'>
                <p className='select_lable'>{translationData?.["select_time"] || "Select time"}</p>
                <Select
                    timeRange={timeRange}
                    timeRangeHandler={timeRangeHandler}
                    error={error}
                />
                <div className='kick_off_section'>
                    <button 
                        onClick={()=>{
                            if (!timeRange) return setError('error')
                            onKickOff(timeRange.value)
                        }} 
                        className='kick_off_btn'
                    >
                        {translationData?.["kick_off"] || "Kick off"}
                    </button>
                </div>
            </div>
        </div>
    )
})

export default KickOffModal