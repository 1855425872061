import {makeObservable, observable, action, computed} from "mobx";
import apiService from "../services/apiServices";
import {cacheService} from "../services/cacheService";
import {microphoneOptionGenerate} from "../utils";
import {MEDIA_DEVICE_STATUS, MICROPHONE_STATUS, PRIVATE_CALL_STATUS} from "./constatns";
import { unityLayoutStore } from "./unityLayoutStore";
import { errorStore } from "./errorStore";

const {disabled, enabled, notFound} = MEDIA_DEVICE_STATUS;
const { setMaxVolume } = unityLayoutStore;
const { setWarning } = errorStore;

class MediaStore {
    isLoading = false;
    videoPermission = false;
    audioPermission = false;
    deviceStatus = disabled;
    audioState = disabled;
    roomCreator = false;
    joinRoom = false;
    joinedUsers = [];
    usersState = [];
    joinPopup = false;
    enableSound = enabled;
    micPermission = false;

    constructor() {
        makeObservable(this, {
            micPermission: observable,
            isLoading: observable,
            videoPermission: observable,
            audioPermission: observable,
            joinPopup: observable,
            deviceStatus: observable,
            joinRoom: observable,
            audioState: observable,
            roomCreator: observable,
            joinedUsers: observable,
            usersState: observable,
            enableSound: observable,
            hasVideoDevice: computed,
            hasVideoIsExist: computed,
            hasAudioDevice: computed,
            hasAudioIsExist: computed,
            checkFreeze: computed,
            allUsersMuted: computed,
            getMyOptions: computed,
            getUserName: action.bound,
            setAudioMute: action.bound,
            setVideoStatus: action.bound,
            setVideo: action.bound,
            setAudioStatus: action.bound,
            setRoomCreator: action.bound,
            setJoinRoom: action.bound,
            hasUserOnFreeze: action.bound,
            setJoinUser: action.bound,
            onUserLeaved: action.bound,
            audioStatus: action.bound,
            removeJoinedUser: action.bound,
            onFreeze: action.bound,
            handleFreeze: action.bound,
            onAddUserAudioState: action.bound,
            onMicStateForUserChanged: action.bound,
            onSpeakingStateForUserChanged: action.bound,
            onRemoveUserAudioState: action.bound,
            setJoinPopup: action.bound,
            joinPopupIsClosed: action.bound,
            setIsLoading: action.bound,
            onUserMicAvailable: action.bound,
            setVideoPermission: action.bound,
            setAudioPermission: action.bound,
            onPrivateCallStateChanged: action.bound,
            userIsInPrivateCall: action.bound,
            getMyChannelUsers: action.bound,
            setSpeakerList: action.bound,
            onMicrophoneUsePermission: action.bound,
            onRoomMicrophonePermissionChanged: action.bound,
            clearParticipentsState: action.bound,
        });
    }

    setIsLoading(bool) {
        return this.isLoading = bool;
    }

    joinPopupIsClosed() {
        return !this.joinPopup;
    }

    setAudioMute() {
        this.enableSound = this.enableSound === enabled ? disabled : enabled;
    }

    setJoinPopup(bool) {
        this.joinPopup = bool;
    }

    setRoomCreator(bool) {
        this.roomCreator = bool;
    }

    setJoinRoom(bool) {
        this.joinRoom = bool;
    }

    getUserName(id) {
        return this.usersState.filter(user => user.publicId === id)[0]?.name;
    }

    get hasVideoDevice() {
        return this.deviceStatus === enabled;
    }

    get hasVideoIsExist() {
        return this.deviceStatus !== notFound;
    }

    get hasAudioDevice() {
        return this.audioState === enabled;
    }

    get hasAudioIsExist() {
        return this.audioState !== notFound;
    }

    get getMyOptions() {
        return this.usersState.filter(user=> {
            return user.publicId === cacheService.getMyId;
        })[0]
    }

    setVideoStatus(status) {
        if (!this.hasVideoIsExist) return;
        this.deviceStatus = status;
    }

    setVideo(status) {
        this.deviceStatus = status;
    }

    audioStatus(status) {
        this.audioState = status;
    }

    setAudioStatus(status) {
        if (this.hasUserOnFreeze(Number(cacheService.get("Id"))) || !this.hasAudioIsExist) return;
        this.audioState = status;
    }

    setJoinUser(name) {
        const result = [...this.joinedUsers];
        result.push(name);
        this.joinedUsers = result;
        setTimeout(() => this.removeJoinedUser(name), 3500);
    }

    onUserLeaved(data) {
    }

    removeJoinedUser(name) {
        this.joinedUsers = this.joinedUsers.filter(item => item !== name);
    }

    //..............................................................
    onAddUserAudioState(name, publicId, talkingValue, micValue, freezedValue, haveMic, isGuest, privateCallState, isFastBankUser, micPermissions){
        const result = [...this.usersState];
        result.push({
            name,
            publicId,
            talkingValue: Boolean(talkingValue),
            micValue: Boolean(micValue),
            freezedValue: Boolean(freezedValue),
            micIsExist: true,
            isGuest: Boolean(isGuest),
            privateCallState: PRIVATE_CALL_STATUS[privateCallState],
            isFastBankUser: !!isFastBankUser,
            micPermissions: !!micPermissions
        })
        this.usersState = result;
    }

    onRemoveUserAudioState(id) {
        const result = [...this.usersState];
        this.usersState = result.filter(item => item.publicId !== id);
    }

    onFreeze(id, state){
        this.usersState = this.usersState.map(user => {
            if(user.publicId === id){
                user.freezedValue = Boolean(state)
            }
            return user;
        });
        if(id === Number(cacheService.get("Id"))) {
            this.deviceStatus = this.deviceStatus === notFound ? notFound : disabled;
            setWarning(state ? 'on_freeze' : 'on_unfreeze')
        };
    }

    hasUserOnFreeze(id = Number(cacheService.get("Id"))) {
        return this.usersState.filter(user => user.publicId === id)[0]?.freezedValue;
    }

    async handleFreeze(id) {
        const event = this.hasUserOnFreeze(id) ? MICROPHONE_STATUS[0] : MICROPHONE_STATUS[2];
        try {
            await apiService.setMuteUnmute(microphoneOptionGenerate(id, event));
        } catch (e) {
            console.warn("ERROR: ", e.message);
        }
    }

    onMicStateForUserChanged(id, status){
        const state = Boolean(status) ? enabled : disabled
        this.usersState = this.usersState.map(user => {
            if(user.publicId === id){
                user.micValue = !!status
            }
            return user
        })
        if(Number(cacheService.get("Id")) === id) this.setAudioStatus(state);
    }

    onUserMicAvailable(id, state){
        this.usersState = this.usersState.map(user => {
            if(user.publicId === id){
                user.micIsExist = Boolean(state)
            }
            return user
        })
    }

    onSpeakingStateForUserChanged(id, status){
    }

    

    setVideoPermission(bool) {
        this.videoPermission = bool
    }

    setAudioPermission(bool) {
        this.audioPermission = bool
    }

    onPrivateCallStateChanged(id, state) {
        this.usersState = this.usersState.map(user => {
            if(user.publicId === id){
                user.privateCallState = PRIVATE_CALL_STATUS[state];
            }
            return user
        })
    }

    userIsInPrivateCall(id) {
        const condidate = this.usersState.filter(user => user.publicId === id && user.privateCallState !== "None")
        return !!condidate[0]
    }

    getMyChannelUsers(channel) {
        return channel.map((user=> {
            const condidate = this.usersState.filter(item => item.publicId === user.uid)[0];
            return {
                ...condidate
            }
        }))
    }

    setSpeakerList(arr) {
        const mergedArray = arr?.map(itm => ({
            ...this.usersState?.find((item) => (item.publicId === itm.uid) && item), ...itm,
        }));
        const speakerUsers = mergedArray.filter(item => item.micValue);
        setMaxVolume(speakerUsers)
    }

    onMicrophoneUsePermission(publicId, micValue){
        this.usersState = this.usersState.map((user)=> {
            if(user.publicId === publicId){
                user.micPermissions = !!micValue;
            }
            return user;
        })
    }

    onRoomMicrophonePermissionChanged(bool){
        this.micPermission = bool;
    }

    clearParticipentsState() {
        this.usersState = []
    }

    get checkFreeze(){
        return this.usersState.filter(user => user.publicId === Number(cacheService.get("Id")))[0]?.freezedValue
    }

    get allUsersMuted(){
        const result = this.usersState.filter(user => user.publicId !== cacheService.getMyId && user.micValue).length
        return !result;
    }

    get videoToolTipContent(){
        return this.deviceStatus === enabled ? "video_on" : this.deviceStatus === notFound ? "not_found" : "video_off";
    }

    get voiceToolTipContent(){
        return this.audioState === enabled ? "audio_on" : this.audioState === notFound ? "not_found" : "audio_off";
    }

    get soundToolTipContent(){
        return this.enableSound === enabled ? "sound_off" : "sound_on";
    }
}

export const mediaStore = new MediaStore();


