import { observer } from "mobx-react-lite"
import {useMemo} from "react";
import { participantsStore } from "../../store/participantsStore";
import BlockUserModal from "../Panels/UserLIstPanel/BlockUserModal/BlockUserModal";
import KickOffModal from "../Panels/UserLIstPanel/KickOff/KickOffModal";
import MakeAdminModal from "../Panels/UserLIstPanel/MakeAdminModal/MakeAdminModal";

const ParticipantsPopup = observer(()=> {
    const {blockUserModal, kickOffModal, makeAdmin, handleKickOff, handleBlockUser} = participantsStore;

    const onBlockUser = ()=> {
        handleBlockUser()
    }
    
    const onKickOff = (interval)=> {
        handleKickOff(interval)
    }

    const isActive = useMemo(() => blockUserModal || kickOffModal || makeAdmin, [blockUserModal, kickOffModal, makeAdmin])

    return (
        <div className={`participants_popups ${isActive ? 'active' : ''}`}>
            {
                blockUserModal && <BlockUserModal onBlock={onBlockUser}/>
            }
            {
                kickOffModal && <KickOffModal onKickOff={onKickOff}/>
            }
            {
                makeAdmin && <MakeAdminModal/>
            }
        </div>
    )
})

export default ParticipantsPopup