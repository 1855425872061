import {createWeb3Modal} from "@web3modal/wagmi/react";
import {defaultWagmiConfig} from "@web3modal/wagmi/react/config";

import {WagmiProvider} from "wagmi";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {bahamut} from "wagmi/chains";

const queryClient = new QueryClient();
const projectId = "978ab44dd0914566c8a6414fa95c5bb1";
// const CHAIN = "5165";

const metadata = {
    name: "Fastexverse",
    description: "Fastexverse | Meta verse",
    url: "https://space.fastexverse.com",
    icons: ["https://space.fastexverse.com/static/media/fastex-verse1.595e9017b5215b1d46562b8684843f6d.svg"]
};

const chains = [bahamut];

const wagmiConfig = defaultWagmiConfig({
    projectId,
    chains,
    ...metadata,
});


createWeb3Modal({
    wagmiConfig,
    projectId,
});

const TanstackProvider = ({children}) => {
    return (
        <WagmiProvider config={wagmiConfig}>
            <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </WagmiProvider>
    );
};
export default TanstackProvider;