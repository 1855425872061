import React from "react";

import {RotateSvg} from "../../ui/assets/svgJs";

import "./index.scss";

const RotateDevice = () => {
    return (
        <div className="rotate-container">
            <RotateSvg />
        </div>
    )
}

export default RotateDevice;