import React from "react";

const MoreSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g id="Icon 24">
                <circle id="Vector" cx="5" cy="12" r="2" stroke="white"
                        strokeWidth="1.5"/>
                <circle id="Vector_2" cx="12" cy="12" r="2" stroke="white"
                        strokeWidth="1.5"/>
                <circle id="Vector_3" cx="19" cy="12" r="2" stroke="white"
                        strokeWidth="1.5"/>
            </g>
        </svg>
    )
}

export default MoreSvg;