import uploadPlaceholder from "../assets/images/modal/thumbnail-camera.png";

export const validateFormKeys = function(key, value) {
    switch(key) {
        case 'imageSrc':
            if(value === uploadPlaceholder) return "upload_image";
            break;
        case "imageFile": {
            if (!value?.length) return "upload_image";
            break;
        }
        case "dropsValue": {
            if (value.length > 49) return "max_charger50";
            if (!value && key === "dropsValue") return "empty_name";
            break;
        }
        case "creator":
        case "owner": {
            if (value.length > 49) return "max_charger50";
            break;
        }
        case "descriptionValue": {
            if (value.length > 249) return "max_charger250";
            if (!value && key === "descriptionValue") return "empty_description";
            break;
        }
        case 'priceValue': {
            if (!Number(value)) return "empty_price";
            if (Number(value) < 0) return "min_price";
            break;
        }
        case 'links': {
            if (!(/^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(value))) return "link_format";
            break;
        }
        default:
            break;
    }
}