import {makeAutoObservable} from "mobx";

class NotificationStore {
    notification = null

    constructor() {
        makeAutoObservable(this);
    }


    setNotification = ({type, text}) => {
        this.notification = {type, text};
    }


    clearNotification = () => {
        this.notification = null
    }
}

export const notificationStore = new NotificationStore();


