import { cacheService } from "../services/cacheService";

export const isValidEmail = email => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);

export const isValidPass = pass => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W])(?=.{8,})/.test(pass);

export const getQueryFromLocation = () => new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

export const generateKey = (pre) => {
  return `${pre}_${new Date().getTime()}`;
};

export const copyTextToClipboard = async (text) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  }
};

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min)) + min;
};

export const usersMostVolumeLevel = (arr) => {
  return arr.filter(
    (prev, current) => prev.level > 6 ? prev : current,
  );
}

export const sortArrayByName = (x, y) => {
  if (x.name.toLowerCase() < y.name.toLowerCase()) {return -1;}
  if (x.name.toLowerCase() > y.name.toLowerCase()) {return 1;}
  return 0;
}

export const microphoneOptionGenerate = (id, status) => {
  return {
    "user_public_id": id,
    "room_public_id": parseInt(cacheService.get("RoomId")),
    "event": status
  };
}

export const capitalize = (string) => string.toString()[0].toUpperCase() + string.toString().slice(1);

const compare = (a, b, value) => {
  const nameA = a.name?.toLowerCase();
  const nameB = b.name?.toLowerCase();

  let comparison = 0;

  if (nameA.indexOf(value) > nameB.indexOf(value)) {
    comparison = 1;
  } else if (nameA.indexOf(value) < nameB.indexOf(value)) {
    comparison = -1;
  }

  return comparison;
}

export const customSort = (data, value) => {
  return data
      .filter(user => (user.name.toLowerCase()).includes(value.toLowerCase()))
      .sort((a, b) => compare(a, b, value));
}

export const downloadFile = (url, filename) => {
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
  })
  .catch(console.error);
}

export const getVideoDimensions = (videoElement) => {
  return new Promise((resolve, reject) => {
    videoElement.addEventListener("loadedmetadata", () => {
      resolve({width: videoElement.videoWidth.toString(), height: videoElement.videoHeight.toString(), videoElement});
    });

    videoElement.addEventListener("error", (e) => {
      console.warn("ERROR: ", e.message);
      reject(e);
    });
  });
};

export const toHHMMSS = (secs) => {
  const secNum = parseInt(secs, 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor((secNum % 3600) / 60);
  const seconds = secNum % 60;

  return [hours, minutes, seconds]
      .map(v => v.toString().padStart(2, '0'))
      .join(':');
};

export const replaceKey = (obj, oldKey, newKey) => {
  const updatedObj = {};
  Object.entries(obj).forEach(([key, value]) => {
    const updatedKey = key === oldKey ? newKey : key;
    updatedObj[updatedKey] = value;
  });
  return updatedObj;
}

export const sizeInMB = (sizeInBytes) => (sizeInBytes / (1024*1024)).toFixed(2);

export const ipfsUriToUrl = (str) => {
  const baseGatewayUrl = "https://gateway.pinata.cloud/ipfs/";
  if (str.includes("ipfs://")) {
    const ipfsHash = str.replace("ipfs://", "");
    return baseGatewayUrl + ipfsHash;
  } else {
    return str;
  }
};