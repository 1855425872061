import { makeObservable, observable, action } from 'mobx'
import { ucraftStore } from './ucraftStore'
import { unityStore } from './unityStore'
import { unityLayoutStore } from './unityLayoutStore'
import apiService from '../services/apiServices'
import { cacheService } from '../services/cacheService'
import { participantsStore } from './participantsStore'
import { mediaStore } from './mediaStore'
import { loadingStore } from './loadingStore'
import {isMobile} from "react-device-detect";

const { setEcommerceDomain, setEcommerceProductId } = ucraftStore
const { setHooryToken, setRoomToken, returnRoomToken } = unityStore
const { setIsPrivate, setIsCreator, setIsDraft, setIsMyRoom } = unityLayoutStore
const { setBlockedUsers, setAdminUsers, setRoomCreatorId, isModerator } = participantsStore
const { setRoomCreator } = mediaStore

class RoomStore {
  likesNum = 0
  isLiked = false
  roomName = ''
  roomBackground = ''
  loadingPercentage = 0
  ticketPopupId = 0
  ticketRoomId = 0
  eventMinutes = 0
  roomMember = false
  timerActive = false
  timeLeft = {}
  isInvalidTicketPopup = false
  creatorName = ''
  needNavigate = false
  showGuestPopup = false
  joinUserRingtone = false
  roomType = null
  ticketBuyingUrl = ""

  constructor() {
    makeObservable(this, {
      likesNum: observable,
      isLiked: observable,
      roomName: observable,
      roomBackground: observable,
      loadingPercentage: observable,
      ticketPopupId: observable,
      eventMinutes: observable,
      roomMember: observable,
      timeLeft: observable,
      timerActive: observable,
      isInvalidTicketPopup: observable,
      creatorName: observable,
      needNavigate: observable,
      showGuestPopup: observable,
      joinUserRingtone: observable,
      ticketBuyingUrl: observable,
      ticketRoomId: observable,
      setNumberLikes: action.bound,
      setIsLiked: action.bound,
      setRoomName: action.bound,
      setRoomBackgroundImg: action.bound,
      clearRoomData: action.bound,
      setLoadingPercentage: action.bound,
      setTicketPopupId: action.bound,
      setTimeLeft: action.bound,
      openInvalidTicketPopup: action.bound,
      getRoomData: action.bound,
      setShowGuestPopup: action.bound,
    })
  }

  setNumberLikes(num) {
    this.likesNum = num
  }

  setIsLiked(bool) {
    this.isLiked = bool
  }

  setRoomName(name) {
    this.roomName = name
  }

  setRoomBackgroundImg(url) {
    this.roomBackground = url
  }

  setLoadingPercentage(num) {
    this.loadingPercentage = num
  }

  setTimeLeft(obj) {
    this.timeLeft = obj
    this.timerActive = true
  }

  setTicketPopupId(roomId, num, ticketBuyingUrl, minutes = 0, isRoomMember = false, needNavigate = false) {
    this.ticketPopupId = num
    this.ticketRoomId = roomId
    this.ticketBuyingUrl = ticketBuyingUrl
    this.eventMinutes = minutes
    this.needNavigate = needNavigate
    this.roomMember = isRoomMember
  }

  openInvalidTicketPopup(bool) {
    this.isInvalidTicketPopup = bool
  }

  setShowGuestPopup(bool) {
    this.showGuestPopup = bool
  }

  async getRoomData(roomId, currentLocation, navigate) {
    if (currentLocation.pathname.includes('dashboard/') && !cacheService.get('Authorization')) {
      loadingStore.toggleUnityLoading(false)
      navigate(`/guest/${roomId}`)
      return navigate(0)
    }
    let ID = cacheService.getMyId
    const { data: { data } } = await apiService.getRoomByID(roomId)
    cacheService.set('CreatorName', data.creator.username)
    this.roomType = data?.room_type

    if (returnRoomToken() !== data?.hoory_website_token) {
      setHooryToken(data?.hoory_website_token)
      setRoomToken(data?.hoory_website_token)
      window.hoorySDK?.reRun({
        websiteToken: data?.hoory_website_token,
        baseUrl: 'https://app.hoory.com',
      })
    }
    setEcommerceDomain(data.ecommerce_domain)
    setEcommerceProductId(data.ecommerce_id)
    setHooryToken(data?.hoory_website_token)
    setBlockedUsers(data.blacklisted_users)
    setAdminUsers(data.admins)
    setRoomCreatorId(data.creator.public_id)
    this.creatorName = data.creator.username

    this.setRoomName(data.room_name)
    this.setRoomBackgroundImg(data.preview_image)
    this.setNumberLikes(data.likes_total_count)
    this.setIsLiked(data.is_liked)
    this.joinUserRingtone = data?.user_ringtone

    if (data.creator.public_id === Number(ID)) {
      setIsCreator(true)
      setRoomCreator(true)
      if (data?.room_state === 3) {
        setIsPrivate(true)
      }
    } else {
      setIsCreator(false)
    }
    let isDraft = data?.room_state === 2
    setIsDraft(isDraft)

    let isMyRoom = data?.room_type === 6 && data.creator.public_id === ID
    setIsMyRoom(isMyRoom)
    if (data?.event_data && cacheService.isGuest) {
      this.setShowGuestPopup(true)
      cacheService.set('AlternateGuest', currentLocation.pathname.split('/dashboard/')[1])
      return unityStore.setLoadUnity(false)
    } else if (data?.event_data && !data?.event_data?.event_member && data?.creator.public_id !== ID && !isModerator()) {
      this.setTicketPopupId(currentLocation.pathname.split('/dashboard/')[1], data.event_data.room_event_id, data.event_data.ticket_buying_url, 0, false, true)
      return unityStore.setLoadUnity(false)
    } else if (data?.event_data && data?.event_data?.event_member && data.event_data?.event_minutes && data.creator.public_id !== ID && !isModerator()) {
      this.setTicketPopupId(currentLocation.pathname.split('/dashboard/')[1],data.event_data.room_event_id, data.event_data.ticket_buying_url, data.event_data.event_minutes, data.event_data.event_member, true)
      return unityStore.setLoadUnity(false)
    }
    return !isMobile ? unityStore.setLoadUnity(true) : null;
  }

  clearRoomData() {
    this.likesNum = 0
    this.isLiked = false
    this.roomName = ''
    this.roomBackground = ''
  }
}

export const roomStore = new RoomStore()
