import { ipfsUriToUrl } from "../utils";

export const convertIpfsUriToUrl = async (selectedCerebrumNft, nftContract)=>{
    const contractBaseURI = await nftContract.methods.tokenURI(selectedCerebrumNft).call();
    const modifiedURI = ipfsUriToUrl(contractBaseURI);
    return modifiedURI
}



export const fetchDataWithRetry = async (uriPath, retryAfterSeconds) => {
    console.log(`Rate limited. Retrying after ${retryAfterSeconds} seconds...`);
    try {
        const response = await fetch(uriPath);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
};


export const getNftDataInfo = async (uri) => {
    try {
        const response = await fetch(uri);
        let data;
        if (!response.ok) {
            if (response.status === 429 && response.headers.get("Retry-After")) {
                const retryAfterSeconds = parseInt(response.headers.get("Retry-After"), 10);
                data = await fetchDataWithRetry(uri, retryAfterSeconds);
            } else {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
        } else {
            data = await response.json();
        }
        return data
    } catch (error) {
        console.error("Error:", error);
    }
}