import React, {useRef} from "react";
import {observer} from "mobx-react-lite";
import {ReactComponent as MetamaskIcon} from "../../assets/icons/metamask.svg";
import {ReactComponent as CopyIcon} from "../../assets/icons/copyIcon.svg";
import {web3Store} from "../../store/web3Store";
import "./style.scss";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {copyTextToClipboard} from "../../utils";
import QRCode from 'qrcode.react';

const MetaMaskPopup = observer(() => {
    const {balance, account, setPopupToggle} = web3Store;
    const ref = useRef(null)

    useOnClickOutside(ref, () => setPopupToggle(false))

    const handleCopy = async () => {
        await copyTextToClipboard(account);
    }

    return (
        <div className={"popup-holder"}>
            <div className={"metamask_popup"} ref={ref}>
                <div className={"metamask_content"}>
                    <div className={"metamask_header"}>
                        <MetamaskIcon/>
                        <h1>MetaMask Wallet</h1>
                    </div>
                    <i className="icon-close meta_close" onClick={() => setPopupToggle(false)}/>
                    <div className={"metamask_qr"}>
                        <h2>Scan address</h2>
                        <QRCode value={account} />
                    </div>
                    <div className={"meta_inputs"}>
                        <label className={"meta_account"}>
                            <h2>Wallet address</h2>
                            <input disabled value={account}/>
                            <CopyIcon className={"copy_meta"} onClick={() => handleCopy()}  />
                        </label>
                        <label className={"meta_balance"}>
                            <h2>Balance</h2>
                            <input disabled value={`${balance}`}/>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default MetaMaskPopup;