import React, { useCallback } from "react";
import { months } from "./constants";
import styles from "./index.module.scss";
import { translationStore } from '../../../../../store/translationStore'
import { observer } from 'mobx-react-lite'

const NftHistoryList = observer(({ nftHistory }) => {
    const getDate = useCallback((date)=> {
        const formatedDate = new Date(date)
        return `${months[formatedDate.getMonth()]}, ${formatedDate.getDay()}, ${formatedDate.getFullYear()}`
    }, []);

    const { translationData } = translationStore

    return (
        <div className={styles.NftHistoryList}>
            {
                nftHistory.length ? nftHistory.map(item=> {
                    return (
                        <div key={item.id} className={styles.history_item}>
                            <div className={styles.left_blok}>
                                <div className={styles.avatar_img}></div>
                                <div>
                                    <p className={styles.user_name}>{item.nft.owned_by.user_name}</p>
                                    <p className={styles.nft_status}>{item.type}</p>
                                </div>
                            </div>
                            <div className={styles.right_blok}>
                                <p  className={styles.price}>{item.nft.currency} {item.nft.price}</p>
                                <p  className={styles.date}>{getDate(item.updated_at)}</p>
                            </div>
                        </div>
                    )
                }) :
                    <p className={styles.not_found}>{translationData?.['not_found'] || 'Not found'}</p>
            }
        </div>
    )
})

export default NftHistoryList;
