import {observer} from "mobx-react-lite";
import {useParams, Outlet, useNavigate, useLocation} from "react-router-dom";
import {useEffect} from "react";

import {translationStore} from "../../store/translationStore.js";

import {Languages} from "../../constants";

const LanguageWrapper = observer(() => {
    const {lang} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const {setActiveLanguage, activeLanguage} = translationStore;

    useEffect(() => {
        if(location.search && location.search.startsWith("?lang=")) {
            navigate(location.search.replace(location.search, location.search.split("?lang=")[1]), {replace: true})
        } else {
            if (lang) {
                const matchingLanguage = Object.values(Languages).find(
                    (language) => language.value === lang
                );
                setActiveLanguage(matchingLanguage || Languages[activeLanguage]);
                if (!matchingLanguage) {
                    navigate(location.pathname.replace(lang, Languages[activeLanguage].value), {replace: true})
                }
            } else {
                navigate(location.pathname.replace("", Languages[activeLanguage].value), {replace: true})
            }
        }
    }, [lang]);

    return <Outlet />
})

export default LanguageWrapper